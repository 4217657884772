






























import { Component, Vue } from "vue-property-decorator";
import {
  FundProjectProgressDto,
  FundProjectYearSummaryDto,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";

@Component({
  name: "fundProjectYearSummaryDetail",
  components: {
    ExportWord,
  },
})
export default class FundProjectProgressDetail extends Vue {
  detail: FundProjectProgressDto = {};
  typeList: any = [];
  styles = "";
  selector = "table-detail-world";
  filename = "项目进展详情表";
  selectedPhotos: string[] = [];

  created() {
    if (this.$route.params.id) {
      this.fetchEnumType();
      this.fetchDetail();
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "NotifyTemplateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取详情
  async fetchDetail() {
    api.fundProjectProgress
      .get({ id: Number(this.$route.params.id) })
      .then((res) => {
        this.detail = { ...res };
        if (this.detail && this.detail.images) {
          this.selectedPhotos = this.detail.images.split(",");
        }
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
